<template>
  <v-row justify="center">
    <v-col data-cy="laboratory-files">
      <v-row v-for="file in building.file" :key="file.id">
        <template v-if="file.contaminate_id == contaminate">
          <v-col :cols="11">
            <v-file-input
              data-cy="lab-return-file"
              :label="file.name || 'Lab return file'"
              disabled
              accept="application/pdf"
            ></v-file-input>
          </v-col>
          <v-col :cols="1">
            <v-icon @click="downloadReturnFile(file.id)" color="secondary" class="pr-2"
              >fas fa-arrow-down</v-icon
            >
            <v-icon @click="removeFile(file.id)" color="red">fas fa-times</v-icon>
          </v-col>
        </template>
      </v-row>
      <v-row>
        <v-col sm="3">
          <v-select
            :items="contaminates"
            :value="contaminate"
            disabled
            item-text="name"
            item-value="id"
            label="Contaminant"
          ></v-select>
        </v-col>
        <v-col>
          <v-file-input
            class="lab-return-file-step"
            :disabled="!contaminate"
            label="Lab return file"
            accept="application/pdf"
            v-model="files[num]"
            @change="upload(num)"
          ></v-file-input>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["building", "fileNum", "contaminate", "contaminates"],
  data: () => ({
    count: [],
    files: [],
    uploaded: [],
  }),
  mounted() {
    this.count = [...Array(this.fileNum).keys()];
  },
  computed: {
    ...mapGetters(["contaminateTypes"]),
    aContaminate: {
      get() {
        return this.contaminate;
      },
      set(updated) {
        this.$emit("update:contaminate", updated);
      },
    },
  },
  methods: {
    ...mapActions([
      "editBuilding",
      "uploadReturnFile",
      "deleteReturnFile",
      "getBuilding",
      "downloadReturnFile",
    ]),
    upload(num) {
      if (this.uploaded.find((x) => x != num) || !this.files[num]) return;
      this.uploadReturnFile({
        contaminate_id: this.contaminate,
        building: this.building.id,
        file: this.files[num],
      }).then(() => {
        this.uploaded.push(num);
        this.getBuilding(this.building.id);
        this.$emit("updated");
      });
    },
    remove(num) {
      const id = this.files[num].id;
      this.removeFile(id);
    },
    removeFile(id) {
      this.deleteReturnFile(id);
      this.getBuilding(this.building.id);
    },
  },
  watch: {
    fileNum(changed) {
      this.count = [...Array(changed).keys()];
    },
    building() {
      this.count = [...Array(this.fileNum).keys()];
      this.files = [];
    },
  },
};
</script>
